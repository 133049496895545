import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';
import GoBackButton from '../../../../components/buttons/GoBackButton';
import DisplayInfo from '../../../../components/info/DisplayInfo';
import useGetRutOrPassport from './hooks/useGetRutOrPassport';
import UserLoader from './parts/BaseLoader';
import { CreateUserForm } from './parts/CreateUserForm/CreateUserForm';
import { DisplayInfoUser } from './parts/DisplayInfoUser/DisplayInfoUser';
import SearchControls from './parts/SearchControls/SearchControls';

const prefix = `usersAndRoles.usersCreate`;

const UserNew = () => {
  const { t } = useTranslation();
  const { queryParams } = useGetRutOrPassport();

  return (
    <Card className="p-4 mt-2 mx-3">
      <Row>
        <Col xs={12} lg={6} className="mb-4">
          <GoBackButton text={t('common.actions.backToList')} />
        </Col>

        <Col md={12}>
          <h2 className="text-primary fs-20 fw-700 mt-4 mb-2 text-uppercase">
            {t(`${prefix}.title`)}
          </h2>
          <p className="fs-16 mt-3 mb-4">{t(`${prefix}.body`)}</p>
        </Col>

        <Col md={12}>
          <SearchControls prefix={prefix} />
        </Col>

        <UserLoader queryParams={queryParams}>
          {({ user, hasQuery, refresh }) => (
            <>
              {!hasQuery && (
                <Col md={12}>
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: '100%', margin: 'auto' }}
                  >
                    <DisplayInfo
                      maxWidth="376px"
                      title={t(`${prefix}.initial.title`)}
                      textBody={t(`${prefix}.initial.body`)}
                    />
                  </div>
                </Col>
              )}

              {hasQuery && <DisplayInfoUser user={user} />}

              <Col md={12}>
                {hasQuery && user && (
                  <CreateUserForm
                    prefix={prefix}
                    defaultValues={user}
                    refresh={refresh}
                  />
                )}
              </Col>
            </>
          )}
        </UserLoader>
      </Row>
    </Card>
  );
};

export default UserNew;
