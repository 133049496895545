import { ReactElement, useCallback } from 'react';
import { Col } from 'reactstrap';
import {
  AxiosResult,
  AxiosResultDefaultError,
} from '../../../../../../api/request';
import { AuthenticationError } from '../../../../../../api/requests/tuitionProcess';
import { createFetchContext } from '../../../../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../../../../components/info/DisplayError';
import Loading from '../../../../../../components/info/Loading';
import { searchRoleById } from '../../../api';
import { Role } from '../../../types/Roles';

interface RoleManageLoaderProps {
  roleId: number | string;
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  undefined,
  Role,
  AuthenticationError | AxiosResultDefaultError
>();

export function useRoleManageLoader() {
  return useFetch().data!;
}

export const RoleManageLoaderConsumer = FetchConsumer;

export default function RoleManageLoader({
  children,
  roleId,
}: RoleManageLoaderProps) {
  const request = useCallback(async (): Promise<
    AxiosResult<any, AxiosResultDefaultError>
  > => {
    return searchRoleById(roleId);
  }, [roleId]);

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ loading, error, refresh, data }) => {
          if (error) {
            if (error.code === 'HTTP_ERROR' && error.status !== 404) {
              return (
                <Col md={12}>
                  <DisplayError
                    insideCard
                    textBody={error.code}
                    retryAction={refresh}
                    loadingAction={loading}
                  />
                </Col>
              );
            }
          }

          if (loading) {
            return (
              <Col md={12}>
                <div className="mx-3">
                  <Loading insideCard />
                </div>
              </Col>
            );
          }

          return children;
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
