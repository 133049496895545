import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Card, Col, Row } from 'reactstrap';

import GoBackButton from '../../../../components/buttons/GoBackButton';
import RoleManageCreateForm from './parts/RoleManageCreateForm';
import RoleManageEditForm from './parts/RoleManageEditForm';
import RoleManageLoader from './parts/RoleManageEditForm/RoleManageLoader';

const RoleManage = () => {
  const { t } = useTranslation();
  const { roleId } = useParams<{ roleId?: string }>();

  return (
    <Card className="p-4 mt-2 mx-3">
      <Row>
        <Col xs={12} lg={6} className="mb-4">
          <GoBackButton text={t('common.actions.backToList')} />
        </Col>
      </Row>

      {roleId ? (
        <RoleManageLoader roleId={roleId}>
          <RoleManageEditForm />
        </RoleManageLoader>
      ) : (
        <RoleManageCreateForm />
      )}
    </Card>
  );
};

export default RoleManage;
