import {
  Button,
  ColumnTable,
  DisplayInfo,
  Icon,
  PaginationType,
  Table,
} from '@octano/global-ui';
import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Col, PopoverBody, Row, UncontrolledPopover } from 'reactstrap';
import { format } from 'rut.js';
import { ModalConfirmation } from '../../../../../components/modals/Confirmation';
import { PathsLayouts } from '../../../../../config/routes';
import useUserState from '../../../../../hooks/useUserState';
import { PermissionName } from '../../../../../types/Auth';
import { useActionAdminAccountStatus } from '../hooks/useActionAdminAccountStatus';
import { AdminAccountRow } from '../types/Users';
import { useAdminAccountLoader } from './AdminAccountLoader';

interface Props {
  dictPrefix: string;
  data: AdminAccountRow[];
  isLoadingResults: boolean;
  pagination: PaginationType | undefined;
  hasFilters?: boolean;
  hasError?: boolean;
}

export default function UserTable({
  dictPrefix,
  data,
  isLoadingResults,
  pagination,
  hasFilters,
  hasError,
}: Readonly<Props>) {
  const { t } = useTranslation();
  const history = useHistory();

  const { isAuthorizedTo } = useUserState();

  const [modalConfirm, setModalConfirm] = useState<boolean>(false);
  const [userToChange, setUserToChange] = useState<AdminAccountRow>();

  const { query, updateQuery } = useAdminAccountLoader();

  const {
    disableAdminAccountS,
    enableAdminAccountS,
  } = useActionAdminAccountStatus({
    refresh: () => updateQuery({ ...query, page: 1 }),
  });

  const onConfirm = () => {
    if (userToChange?.isEnabled) {
      disableAdminAccountS(userToChange);
    } else if (userToChange) {
      enableAdminAccountS(userToChange);
    }
  };

  const onClose = () => {
    setModalConfirm(false);
    setUserToChange(undefined);
  };

  const columns: ColumnTable<AdminAccountRow>[] = [
    {
      headerText: t(`${dictPrefix}.columns.studentName`),
      columnName: 'fullName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '16,66%',
      cellFormat: ({ row }) => {
        return (
          <p>
            {row?.fullName}
            <br />
            {row?.run && format(row.run)}
            {row?.passportNumber && row.passportNumber}
          </p>
        );
      },
    },
    {
      headerText: t(`${dictPrefix}.columns.rol`),
      columnName: 'roleName',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '16,66%',
      cellFormat: ({ row }) => {
        const key = `key_${row.roleId.toString()}_${new Date().getTime()}`;
        return (
          <>
            <span id={key}>
              <p className="mb-0" id={`${row.roleName}`}>
                {row.roleName}
              </p>
              <Icon name="eye" size={16} />
            </span>
            <UncontrolledPopover placement="right" target={key}>
              <PopoverBody style={{ maxHeight: '300px', overflow: 'auto' }}>
                {row.roleDescription}
              </PopoverBody>
            </UncontrolledPopover>
          </>
        );
      },
    },
    {
      headerText: t(`${dictPrefix}.columns.email`),
      columnName: 'email',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '16,66%',
    },
    {
      headerText: t(`${dictPrefix}.columns.phone`),
      columnName: 'phone',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '10%',
    },
    {
      headerText: t(`${dictPrefix}.columns.updateAt`),
      columnName: 'updatedAt',
      tdClassName: 'text-center',
      thClassName: 'text-center',
      width: '10%',
      cellFormat: (options) => dayjs(options.value).format('DD/MM/YYYY'),
    },
    {
      headerText: t(`${dictPrefix}.columns.action`),
      columnName: 'accountId',
      thClassName: 'text-center',
      tdClassName: 'text-center',
      width: '26%',
      cellFormat: ({ row }) => {
        return (
          <Row>
            <Col sm={'12'} md={'12'} lg={'6'}>
              <Button
                style={{
                  height: '50px',
                }}
                fullwidth
                text={
                  row?.isEnabled
                    ? t(`${dictPrefix}.columns.disableUser`)
                    : t(`${dictPrefix}.columns.enableUser`)
                }
                size="sm"
                color={row?.isEnabled ? 'danger' : 'primary'}
                outlined
                onClick={() => {
                  if (
                    isAuthorizedTo([
                      PermissionName.MANAGEMENT_TOOLS_USERS_AND_ROLES,
                    ])
                  ) {
                    setUserToChange(row);
                    setModalConfirm(true);
                  }
                }}
              />
            </Col>
            <Col sm={'12'} md={'12'} lg={'6'}>
              <Button
                style={{
                  height: '50px',
                }}
                fullwidth
                text={t(`${dictPrefix}.columns.EditUser`)}
                size="sm"
                onClick={() => {
                  if (
                    isAuthorizedTo([
                      PermissionName.MANAGEMENT_TOOLS_USERS_AND_ROLES,
                    ])
                  ) {
                    if (row.run) {
                      history.push(
                        `${PathsLayouts.usersAndRoles}/users/create/${row.run}`,
                      );
                    } else {
                      history.push(
                        `${PathsLayouts.usersAndRoles}/users/create/${row.countryId}|${row.passportNumber}`,
                      );
                    }
                  }
                }}
              />
            </Col>
          </Row>
        );
      },
    },
  ];

  const noResultsText = useMemo(() => {
    let title: string;
    if (hasFilters) {
      title = t(`${dictPrefix}.noResultsFilter.description`);
    } else {
      title = t(`${dictPrefix}.noResults.description`);
    }

    return (
      <div className="d-flex justify-content-center align-items-center py-5">
        <DisplayInfo
          iconName="information"
          title={title}
          textBody=""
          maxWidth="650px"
        />
      </div>
    );
  }, [t, dictPrefix, hasFilters]);

  return (
    <>
      <Table
        isLoadingResults={isLoadingResults}
        data={data}
        columns={columns}
        pagination={pagination}
        noResultsText={noResultsText}
      />

      <ModalConfirmation
        open={modalConfirm}
        title={
          userToChange?.isEnabled
            ? '¿Estas seguro de deshabilitar al usuario?'
            : '¿Estas seguro de habilitar al usuario?'
        }
        subtitle={
          userToChange?.isEnabled
            ? 'Se revocaran los permisos al mismo, si lo necesitas podras habilitarlo y editar sus permisos nuevamente.'
            : 'El usuario quedará activo para iniciar sesión y con los permisos asignados a su rol.'
        }
        onConfirm={onConfirm}
        onClose={onClose}
      />
    </>
  );
}
