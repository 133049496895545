import { DateInput, Select, TextInput } from '@octano/global-ui';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, FormGroup, Row } from 'reactstrap';
import { format as formatRut } from 'rut.js';

import SectionTitle from '../../../../../../../components/text/SectionTitle';
import { useParameters } from '../../../../../../../hooks/useParameters';
import { useValidations } from '../../../../../../../hooks/useValidations';
import { formatDate } from '../../../../../../../utils/dates';
import StudentPhoto, {
  StudentPhotoTexts,
} from '../../../../../../TuitionProcess/parts/StudentFile/StudentPhoto';

type StudentBackgroundNoSuaProps = {
  postulantId?: string | number;
  photo: string | null;
  isStudentFileUpdate?: boolean;
  fieldNames?: {
    rut: string;
    passportCountry: string;
    passportNumber: string;
    namesStudent: string;
    paternalSurname: string;
    maternalSurname: string;
    cv: string;
    studyPlan: string;
    campus: string;
    schedule: string;
    entryYear: string;
    nationality: string;
    birthday: string;
    personalEmail: string;
    officialEmail: string;
  };
  disabled?: boolean;
  hasEmail?: boolean;
};

/**
 * Sección de Antecedentes del estudiante SIN SUA
 */

const StudentBackgroundNoSua = ({
  postulantId,
  photo,
  isStudentFileUpdate = false,
  fieldNames = {
    rut: 'rut',
    passportCountry: 'passportCountry',
    passportNumber: 'passportNumber',
    namesStudent: 'namesStudent',
    paternalSurname: 'paternalSurname',
    maternalSurname: 'maternalSurname',
    cv: 'cv',
    studyPlan: 'studyPlan',
    campus: 'campus',
    schedule: 'schedule',
    entryYear: 'entryYear',
    nationality: 'nationality',
    birthday: 'birthday',
    personalEmail: 'personalEmail',
    officialEmail: 'officialEmail',
  },
}: StudentBackgroundNoSuaProps) => {
  const { control, watch } = useFormContext();
  const { t } = useTranslation();
  const { validateTextNotEmpty } = useValidations();
  const { countryOptions, nationalityOptions } = useParameters();

  const prefix = `tuitionProcess.onSite.studentFile.backgroundSection`;

  const [rut, birthday] = watch(['rut', 'birthday']);

  const studentPhotoTexts = t<any, StudentPhotoTexts>(
    `tuitionProcess.onSite.studentFile.studentPhoto`,
    { returnObjects: true },
  );

  return (
    <>
      <Row>
        <Col xs={12}>
          <SectionTitle text={t(`${prefix}.title`)} />
        </Col>
      </Row>
      <Row>
        <Col className="pb-5" xs={12} lg="auto">
          <FormGroup>
            <div className="m-auto mw-100" style={{ width: '121px' }}>
              <StudentPhoto
                postulantId={postulantId}
                texts={studentPhotoTexts}
                defaultPhoto={photo}
                isStudentFileUpdate={isStudentFileUpdate}
              />
            </div>
          </FormGroup>
        </Col>
        <Col xs={12} lg={true}>
          <Row>
            <Col className="pb-3" xs={12} lg={6}>
              {rut ? (
                <TextInput
                  name={`${fieldNames.rut}`}
                  label={t(`${prefix}.${fieldNames.rut}`)}
                  formatter="rut"
                  value={formatRut(rut)}
                  defaultValue={formatRut(rut)}
                  disabled
                  shouldUnregister={true}
                />
              ) : (
                <Row>
                  <Col xs={5} className="pr-1 pr-md-2">
                    <Select
                      name={`${fieldNames.passportCountry}`}
                      label={t(`${prefix}.${fieldNames.passportCountry}`)}
                      options={countryOptions}
                      isClearable={false}
                      control={control}
                      shouldUnregister={true}
                      disabled
                    />
                  </Col>
                  <Col xs={7} className="pl-1 pl-md-2">
                    <TextInput
                      name={`${fieldNames.passportNumber}`}
                      label={t(`${prefix}.${fieldNames.passportNumber}`)}
                      control={control}
                      shouldUnregister={true}
                      disabled
                    />
                  </Col>
                </Row>
              )}
            </Col>
            <Col className="pb-3" xs={12} lg={6}>
              <TextInput
                name={`${fieldNames.namesStudent}`}
                label={t(`${prefix}.${fieldNames.namesStudent}`)}
                control={control}
                disabled={!isStudentFileUpdate}
              />
            </Col>
            <Col className="pb-3" xs={12} lg={6}>
              <TextInput
                name={`${fieldNames.paternalSurname}`}
                label={t(`${prefix}.${fieldNames.paternalSurname}`)}
                control={control}
                disabled={!isStudentFileUpdate}
              />
            </Col>
            <Col className="pb-3" xs={12} lg={6}>
              <TextInput
                name={`${fieldNames.maternalSurname}`}
                label={t(`${prefix}.${fieldNames.maternalSurname}`)}
                control={control}
                disabled={!isStudentFileUpdate}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {!isStudentFileUpdate && (
          <>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name={`${fieldNames.cv}`}
                label={t(`${prefix}.${fieldNames.cv}`)}
                control={control}
                disabled
              />
            </Col>
            <Col className="pb-3" xs={12} lg={8}>
              <TextInput
                name={`${fieldNames.studyPlan}`}
                label={t(`${prefix}.${fieldNames.studyPlan}`)}
                control={control}
                disabled
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name={`${fieldNames.campus}`}
                label={t(`${prefix}.${fieldNames.campus}`)}
                control={control}
                disabled
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name={`${fieldNames.schedule}`}
                label={t(`${prefix}.${fieldNames.schedule}`)}
                control={control}
                disabled
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name={`${fieldNames.entryYear}`}
                label={t(`${prefix}.${fieldNames.entryYear}`)}
                control={control}
                disabled
              />
            </Col>
          </>
        )}
        <Col className="pb-3" xs={12} lg={4}>
          <Select
            name={`${fieldNames.nationality}`}
            label={t(`${prefix}.${fieldNames.nationality}`)}
            control={control}
            options={nationalityOptions}
            isSearchable={true}
            rules={{ validate: validateTextNotEmpty }}
          />
        </Col>
        {isStudentFileUpdate && (
          <>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name={`${fieldNames.personalEmail}`}
                label={t(`${prefix}.${fieldNames.personalEmail}`)}
                control={isStudentFileUpdate ? control : undefined}
                rules={{ validate: validateTextNotEmpty }}
              />
            </Col>
            <Col className="pb-3" xs={12} lg={4}>
              <TextInput
                name={`${fieldNames.officialEmail}`}
                label={t(`${prefix}.${fieldNames.officialEmail}`)}
                control={isStudentFileUpdate ? control : undefined}
                disabled
              />
            </Col>
          </>
        )}
        <Col className="pb-3" xs={12} lg={4}>
          {!isStudentFileUpdate ? (
            <TextInput
              name={`${fieldNames.birthday}`}
              label={t(`${prefix}.${fieldNames.birthday}`)}
              value={birthday ? formatDate(birthday, 'DD-MM-YYYY') : ''}
              disabled
            />
          ) : (
            <DateInput
              name={`${fieldNames.birthday}`}
              label={t(`${prefix}.${fieldNames.birthday}`)}
              control={control}
              rules={{ validate: validateTextNotEmpty }}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default StudentBackgroundNoSua;
