import { SearchStudentCheckingAccount } from '../types';
import { STUDENT_CHECKING_ACCOUNT } from '../../../api/endpoints';
import request from '../../../api/request';

export const searchByRut = (rut: string) => {
  let url = STUDENT_CHECKING_ACCOUNT.GET_ADMIN_ACCOUNT_BY_RUT(rut);
  return request<SearchStudentCheckingAccount>(url);
};

export const searchByPassport = (country: string, passportNumber: string) => {
  let url = STUDENT_CHECKING_ACCOUNT.GET_ADMIN_ACCOUNT(country, passportNumber);
  return request<SearchStudentCheckingAccount>(url);
};
