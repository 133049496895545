import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { Card, Col } from 'reactstrap';
import GoBackButton from '../../../../../../components/buttons/GoBackButton';
import RoleManageLoader from '../RoleManageEditForm/RoleManageLoader';
import { PermissionStateProvider } from './hooks/userPermissionState';
import PermissionDisplay from './parts/PermissionDisplay';
import PermissionsLoader from './parts/PermissionsLoader';

function PermissionAssignment() {
  const { roleId } = useParams<{ roleId: string }>();
  const { t } = useTranslation();
  return (
    <Card className="p-4 mt-2 mx-3">
      <Col className="mb-4">
        <GoBackButton text={t('common.actions.backToList')} />
      </Col>
      <Col sm={12}>
        <p className="text-primary fs-20 fw-700 mt-4 mb-1 text-uppercase">
          ASIGNACIÓN DE PERMISOS
        </p>
      </Col>
      <Col sm={12}>
        <p className="fs-16  mb-4">
          A continuación, define los permisos que tendrá este rol. Los permisos
          se asignan mediante conjuntos de permisos, puedes seleccionar más de
          un conjunto.
        </p>
      </Col>
      {/* Trae data del rol y sus permisos */}
      <RoleManageLoader roleId={roleId}>
        {/* Ofrece un estado para los permisos seleccionados en las distintas tablas */}
        <PermissionStateProvider>
          {/* Trae los permisos, sedes, y escuelas de la base de datos */}
          <PermissionsLoader>
            <PermissionDisplay />
          </PermissionsLoader>
        </PermissionStateProvider>
      </RoleManageLoader>
    </Card>
  );
}

export default PermissionAssignment;
