import { format as formatRut } from 'rut.js';
import { parsePhoneNumber } from 'libphonenumber-js';

import { getDate } from '../../../utils/dates';
import { getSelectedOption } from '../../../utils/selectFormat';
import {
  StudentCheckingAccountForm,
  SearchStudentCheckingAccount,
  onSearch,
} from '../types';

export const adminAccountAdapter = (
  student: SearchStudentCheckingAccount,
  values: onSearch | null,
  options: { countryOptions: any; roleOptions: any },
): StudentCheckingAccountForm => {
  const { ...data } = student;
  const parsedPhone = data?.Account_phone
    ? parsePhoneNumber(data?.Account_phone, 'CL')
    : null;

  const email = data.Account_email ?? '';

  return {
    id: data.Account_id,
    rut: values?.rut ? values.rut : '',
    passportNumber: data.Account_passportNumber
      ? data.Account_passportNumber
      : '',
    passportCountry: values?.country
      ? getSelectedOption(values.country, options.countryOptions)
      : undefined,
    names: data.Account_name,
    lastnamePather: data.Account_paternalLastName,
    lastnameMother: data.Account_maternalLastName,
    email: email,
    personalEmail: data.Account_personalEmail ?? '',
    phone: parsedPhone?.isValid() ? parsedPhone?.formatInternational() : '',
    birthdate: data.Account_birthday
      ? getDate(data.Account_birthday)
      : undefined,
    nationality: data.Nationality_id
      ? getSelectedOption(data.Nationality_id, options.countryOptions)
      : undefined,
    role: data.Role_id
      ? getSelectedOption(data.Role_id, options.roleOptions)
      : undefined,
    teacherId: data.Account_teacherId,
    studentId: data.Account_studentId,
    isActive: data.Account_isActive,
  };
};

export const adminAccountEmptyAdapter = (
  values: onSearch | null,
  countryOptions: any,
): StudentCheckingAccountForm => ({
  id: undefined,
  rut: values?.rut ? formatRut(values?.rut) : '',
  passportNumber: values?.passport ? values.passport : '',
  passportCountry: values?.country
    ? getSelectedOption(values.country, countryOptions)
    : undefined,
  names: '',
  lastnamePather: '',
  lastnameMother: '',
  email: '',
  personalEmail: '',
  phone: '',
  birthdate: undefined,
  nationality: undefined,
  role: undefined,
  isActive: false,
});
