import { TeacherAccountData } from '../../types/Teacher';
import { TEACHER } from '../endpoints';
import request from '../request';

/**
 * Busca un docente por rut
 * @param rut El id del docente
 * @returns 404 si no existe o el TeacherAccountData de ese docente en caso de existir
 */
export const searchTeacherByRut = (rut: string) => {
  return request<TeacherAccountData>(TEACHER.SEARCH, { params: { rut } });
};

/**
 * Envia un email para activar cuenta docente y actualiza el correo. Si la cuenta no existia, se crea. Si al crearla se detecta que hay otra cuenta con el mismo RUT, se notifica el conflicto. Si se pasa la bandera ignoreWarnings: true se sobrepasa cualquier error
 * @param data Datos del docente
 * @returns De haber algun conflicto retorna un array de mensajes (strings)
 */
export const sendTeacherAccountActivationLink = (values: {
  email: string;
  rut: string;
  name: string;
  personalEmail: string;
  paternalLastName: string;
  maternalLastName: string;
  birthday: string;
  ignoreWarnings?: boolean;
}) => {
  const surpassErrors = values.ignoreWarnings ?? false;
  const data = { ...values, ignoreWarnings: surpassErrors };
  return request<{ message?: string[] }>(TEACHER.SEND_ACTIVATION_LINK, {
    data,
    method: 'POST',
  });
};

/**
 * Envia un email para recuperar la contraseña y actualiza el correo del docente
 * @param data Email y rut del usuario
 * @returns
 */
export const sendTeacherPasswordRecoveryLink = (data: {
  email: string;
  rut: string;
}) => {
  return request(TEACHER.SEND_PASSWORD_RECOVERY_LINK, {
    method: 'POST',
    data,
  });
};

/**
 * Coloca una cuenta de docente como inactiva
 * @param data Email y rut del usuario
 * @returns
 */
export const deactivateTeacherAccount = (data: {
  email: string;
  rut: string;
}) => {
  return request(TEACHER.DEACTIVATE_TEACHER, { method: 'POST', data });
};
