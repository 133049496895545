import request from '../../../../../api/request';
import { SearchParams } from '../types/Filters';
import { ResponseAdminAccounts } from '../types/Users';

const USERS = {
  GET_ADMIN_ACCOUNT_LIST: '/maintainers-portal/admin-account',
  PUT_ADMIN_ACCOUNT_STATUS: (accountId: number | string) =>
    `/maintainers-portal/admin-account/${accountId}`,
};

/**
 * Obtiene el listado de las usuarios de tipo administrador
 * @param items
 * @param page
 * @returns
 */
export function getAdminAccountList(
  filter: { items: number; page: number } & SearchParams,
) {
  const URL = USERS.GET_ADMIN_ACCOUNT_LIST;
  const method = 'GET';
  const params = { ...filter, items_per_page: filter.items };
  return request<ResponseAdminAccounts>(URL, { method, params });
}
