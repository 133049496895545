import { useTranslation } from 'react-i18next';
import { Card, Col, Row } from 'reactstrap';
import DisplayInfo from '../../components/info/DisplayInfo';
import useGetRutOrPassport from './hooks/useGetRutOrPassport';
import UserLoader from './parts/BaseLoader';
import SearchControls from './parts/SearchControls/SearchControls';

const prefix = `studentCheckingAccount`;

const StudentCheckingAccount = () => {
  const { t } = useTranslation();
  const { queryParams } = useGetRutOrPassport();

  return (
    <Card className="p-4 mt-2 mx-3">
      <Row>
        <Col md={12}>
          <p className="fs-16 mt-3 mb-4">{t(`${prefix}.body`)}</p>
        </Col>

        <Col md={12}>
          <SearchControls prefix={prefix} />
        </Col>

        <UserLoader queryParams={queryParams}>
          {({ user, hasQuery, refresh }) => (
            <>
              {!hasQuery && (
                <Col md={12}>
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ height: '100%', margin: 'auto' }}
                  >
                    <DisplayInfo
                      maxWidth="376px"
                      title={t(`${prefix}.initial.title`)}
                      textBody={t(`${prefix}.initial.body`)}
                    />
                  </div>
                </Col>
              )}

              <Col md={12}>{hasQuery && user && <>AQUI VA LA TABLA</>}</Col>
            </>
          )}
        </UserLoader>
      </Row>
    </Card>
  );
};

export default StudentCheckingAccount;
