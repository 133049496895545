import { Button, PaginationType } from '@octano/global-ui';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from 'reactstrap';
import { PathsLayouts } from '../../../../config/routes';
import { useAdminAccountLoader } from './parts/AdminAccountLoader';
import { SearchControls } from './parts/SearchControls';
import UserTable from './parts/UserTable';
import { ITEMS_PER_PAGE_DEFAULT } from './types/Users';

export default function UserList({ dictPrefix }: { dictPrefix: string }) {
  const { error, loading, data, query, updateQuery } = useAdminAccountLoader();
  const history = useHistory();

  const paginationTable = useMemo<PaginationType | undefined>(() => {
    if (data) {
      return {
        totalItems: data.total,
        onChangePage: (page) => updateQuery({ ...query, page }),
        itemsPerPage: ITEMS_PER_PAGE_DEFAULT,
        totalPages: data.total_pages,
        currentPage: query.page,
      };
    } else return undefined;
  }, [data, query, updateQuery]);

  return (
    <Card className="mx-3 p-4">
      <div className="mb-5">
        <SearchControls dictPrefix={`${dictPrefix}.searchControls`} />

        <div className="d-flex flex-justify-content">
          <Button
            className="ml-auto"
            size="md"
            text={'crear nuevo usuario'}
            icon="plus"
            onClick={() => {
              history.push(`${PathsLayouts.usersAndRoles}/users/create/`);
            }}
          />
        </div>
      </div>

      <UserTable
        dictPrefix={`${dictPrefix}.table`}
        isLoadingResults={loading}
        data={data?.data ?? []}
        pagination={paginationTable}
        hasError={Boolean(error)}
        hasFilters={Object.values(query).some((value) => value !== null)}
      />
    </Card>
  );
}
