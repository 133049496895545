import { ReactElement, useCallback } from 'react';

import {
  AxiosResult,
  AxiosResultDefaultError,
} from '../../../../../api/request';
import { AuthenticationError } from '../../../../../api/requests/tuitionProcess';
import { createFetchContext } from '../../../../../components/contexts/FetchContextFactory';
import { getAdminAccountList } from '../services';
import { onSearch } from '../types/Filters';
import { ITEMS_PER_PAGE_DEFAULT, ResponseAdminAccounts } from '../types/Users';

interface BaseLoaderProps {
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  onSearch,
  ResponseAdminAccounts,
  AuthenticationError | AxiosResultDefaultError
>();

export function useAdminAccountLoader() {
  const context = useFetch();

  return {
    error: context.error,
    loading: context.loading,
    data: context.data,
    query: context.query,
    updateQuery: context.updateQuery,
  };
}

export const AdminAccountLoaderConsumer = FetchConsumer;

export default function AdminAccountLoader({ children }: BaseLoaderProps) {
  const request = useCallback(async (query: onSearch): Promise<
    AxiosResult<ResponseAdminAccounts, AxiosResultDefaultError>
  > => {
    return getAdminAccountList({
      items: ITEMS_PER_PAGE_DEFAULT,
      page: query.page - 1,
      search: query?.searchParams?.search,
      email: query?.searchParams?.email,
      roleId: query?.searchParams?.roleId,
    });
  }, []);

  return (
    <FetchProvider
      request={request}
      defaultQuery={{ page: 1 }}
      fetchImmediately
    >
      {children}
    </FetchProvider>
  );
}
