import { ReactElement, useCallback, useMemo } from 'react';
import { Col } from 'reactstrap';

import { AxiosResult, AxiosResultDefaultError } from '../../../api/request';
import { AuthenticationError } from '../../../api/requests/tuitionProcess';
import { createFetchContext } from '../../../components/contexts/FetchContextFactory';
import DisplayError from '../../../components/info/DisplayError';
import Loading from '../../../components/info/Loading';
import { useParameters } from '../../../hooks/useParameters';
import { searchByPassport, searchByRut } from '../services/index';
import {
  StudentCheckingAccountForm,
  SearchStudentCheckingAccount,
  onSearch,
} from '../types';
import {
  adminAccountAdapter,
  adminAccountEmptyAdapter,
} from '../utils/adminAccountAdapter';
import { useTranslation } from 'react-i18next';

interface UserLoaderProps {
  queryParams: onSearch | null;
  children: (props: {
    user: StudentCheckingAccountForm;
    hasQuery: boolean;
    hasPassport: boolean;
    refresh: () => void;
  }) => ReactElement;
}

const { FetchProvider, FetchConsumer } = createFetchContext<
  undefined,
  SearchStudentCheckingAccount,
  AuthenticationError | AxiosResultDefaultError
>();

export const UserLoaderConsumer = FetchConsumer;

export default function UserLoader({ children, queryParams }: UserLoaderProps) {
  const prefix = `studentCheckingAccount`;
  const { t } = useTranslation();

  const request = useCallback(async (): Promise<
    AxiosResult<SearchStudentCheckingAccount, AxiosResultDefaultError>
  > => {
    return queryParams?.rut
      ? await searchByRut(queryParams?.rut)
      : await searchByPassport(
          queryParams?.country ?? '',
          queryParams?.passport ?? '',
        );
  }, [queryParams]);

  const hasQuery = useMemo(
    () => (!queryParams ? false : Object.keys(queryParams).length > 0),
    [queryParams],
  );
  const hasPassport = useMemo(
    () => (!queryParams ? false : Object.keys(queryParams).length === 2),
    [queryParams],
  );
  const { countryOptions, roleOptions } = useParameters();

  return (
    <FetchProvider request={request} defaultQuery={undefined} fetchImmediately>
      <FetchConsumer>
        {({ data, loading, error, refresh }) => {
          if (error) {
            if (error.code === 'HTTP_ERROR' && error.status === 404) {
              return (
                <Col md={12}>
                  <DisplayError
                    insideCard
                    title={t(`${prefix}.notFound.title`)}
                    textBody={t(`${prefix}.notFound.body`)}
                    loadingAction={loading}
                  />
                </Col>
              );
            }
            if (error.code === 'HTTP_ERROR' && error.status !== 404) {
              return (
                <Col md={12}>
                  <DisplayError
                    insideCard
                    textBody={error.code}
                    retryAction={refresh}
                    loadingAction={loading}
                  />
                </Col>
              );
            }
          }

          if (loading) {
            return (
              <Col md={12}>
                <div className="mx-3">
                  <Loading insideCard />
                </div>
              </Col>
            );
          }

          return children({
            user:
              !data || error
                ? adminAccountEmptyAdapter(queryParams, countryOptions)
                : adminAccountAdapter(data, queryParams, {
                    countryOptions,
                    roleOptions,
                  }),
            hasQuery,
            hasPassport,
            refresh,
          });
        }}
      </FetchConsumer>
    </FetchProvider>
  );
}
