import { Button, TextOutlinedInput } from '@octano/global-ui';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useValidations } from '../../../hooks/useValidations';

type FormValues = {
  rut: string;
};

interface Props {
  handleSearch: (formValues: FormValues) => void;
}

export default function Search({ handleSearch = () => null }: Props) {
  const { t } = useTranslation();
  const prefix = 'teacherActivation.searchBar';
  const { validateRut } = useValidations();
  const {
    handleSubmit,
    control,
    formState,
    watch,
    setValue,
  } = useForm<FormValues>({
    defaultValues: {
      rut: '',
    },
    mode: 'onChange',
  });

  const rut = watch('rut');

  useEffect(() => {
    const newValue = rut?.replace('.', '').replace('-', '').toUpperCase();
    setValue('rut', newValue);
  }, [setValue, rut]);

  return (
    <form
      onSubmit={handleSubmit(handleSearch)}
      className="d-flex align-items-start"
    >
      <TextOutlinedInput
        label={t(`${prefix}.rut`)}
        name="rut"
        control={control}
        rules={{
          validate: {
            rut: validateRut,
          },
        }}
      />
      <Button
        type="submit"
        text={t(`common.actions.search`)}
        size="sm"
        className="ml-4"
        style={{ height: 40, marginTop: 21 }}
        disabled={Object.keys(formState.errors).length > 0 || !rut}
      />
    </form>
  );
}
